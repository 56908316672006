<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button title="Open Settings" class="md-primary md-just-icon md-round" @click.stop="openSettings">
                <md-icon>settings</md-icon>
            </md-button>
            <md-button
                title="Add custom action"
                class="md-primary md-just-icon md-round"
                @click.stop="handleAddCustomAction"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div>
            <SmsSettings></SmsSettings>
        </div>
        <div v-if="filteredCustomActions && filteredCustomActions.length">
            <md-table class="context-menu-support custom-paginated-table">
                <md-table-row>
                    <md-table-head>Event Trigger</md-table-head>
                    <md-table-head>Recipient(s)</md-table-head>
                    <md-table-head>Stop Type(s)</md-table-head>
                    <md-table-head>Time Delay</md-table-head>
                    <md-table-head class="end">Actions</md-table-head>
                </md-table-row>
                <md-table-row v-for="item in filteredCustomActions" :key="item.teamCustomActionId">
                    <md-table-cell>
                        {{ item.eventTriggerName + toFilterString(item) }}
                        <template v-if="item.settings.templateName">
                            <br />
                            <span>{{ `(${item.settings.templateName})` }}</span>
                        </template>
                    </md-table-cell>
                    <md-table-cell>{{ toSmsRecipientTypesText(item.settings.smsRecipientTypes) }}</md-table-cell>
                    <md-table-cell>
                        {{ toStopTypeString(item.filter.stopTypes) }}
                    </md-table-cell>
                    <md-table-cell>
                        {{ toTimeDelayDesc(item.settings.timeDelay) }}
                    </md-table-cell>
                    <md-table-cell align="end" class="action-buttons">
                        <md-button
                            v-if="hasCustomTemplate(item)"
                            title="Update custom template"
                            class="md-info md-just-icon md-round"
                            @click.stop="handleUpdateTemplate(item)"
                            :data-test-id="`btn-update-template-${item.teamCustomActionId}`"
                        >
                            <md-icon>subject</md-icon>
                        </md-button>
                        <md-button
                            title="Update custom action"
                            class="md-warning md-just-icon md-round"
                            @click.stop="handleUpdate(item.teamCustomActionId)"
                            :data-test-id="`btn-update-${item.teamCustomActionId}`"
                        >
                            <md-icon>edit</md-icon>
                        </md-button>
                        <md-button
                            title="Delete custom action"
                            class="md-danger md-just-icon md-round"
                            @click.stop="handleDelete(item.teamCustomActionId)"
                            :data-test-id="`btn-delete-${item.teamCustomActionId}`"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
        <div v-else>
            <p class="no-result-message">No actions found.</p>
        </div>
    </div>
</template>

<script>
import { showErrorMessage } from '@/helpers';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapActions, mapGetters } from 'vuex';
import {
    TEAM_CUSTOM_ACTION_SMS_RECIPIENT_OPTIONS,
    TEAM_CUSTOM_ACTION_STOP_TYPE_OPTIONS
} from '@/utils/CustomActionConstants';
import { TEAM_CUSTOM_ACTION_DEFAULTS } from '@/utils/defaults';
import CreateCustomAction from '../CreateCustomAction';
import SmsTemplateModal from './SmsTemplateModal';
import ActionLevelSettings from '../ActionLevelSettings';
import SmsSettings from './SmsSettings';

export default {
    mixins: [GeneralMixin],
    props: {
        eventTrigger: { type: String, default: null }
    },
    components: { SmsSettings },
    data() {
        return {
            action: 'Sms',
            selectedCustomAction: null,
            smsRecipientOptions: TEAM_CUSTOM_ACTION_SMS_RECIPIENT_OPTIONS,
            stopTypeOptions: TEAM_CUSTOM_ACTION_STOP_TYPE_OPTIONS
        };
    },
    computed: {
        ...mapGetters({ customActions: 'custom-actions/smsCustomActions' }),
        filteredCustomActions() {
            return this.customActions
                .filter((x) => x.action === this.action)
                .sort((a, b) =>
                    a.eventTriggerName + this.toFilterString(a) > b.eventTriggerName + this.toFilterString(b) ? 1 : -1
                );
        }
    },
    methods: {
        ...mapActions('custom-actions', ['FETCH_EVENT_TRIGGERS', 'DELETE_EVENT_TRIGGER']),
        handleAddCustomAction() {
            const customAction = { ...TEAM_CUSTOM_ACTION_DEFAULTS() };

            customAction.action = this.action;
            this.$modal
                .show(CreateCustomAction, {
                    customActionModel: customAction,
                    isDisabled: true,
                    update: false
                })
                .then(({ result, model }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        handleUpdateTemplate(customAction) {
            this.$modal.show(SmsTemplateModal, { customAction }).then((resp) => {
                //
            });
        },
        handleUpdate(teamCustomActionId) {
            const customAction = this.customActions.find((x) => x.teamCustomActionId === teamCustomActionId);

            if (!customAction) 
                return;

            const customActionModel = cloneDeep(customAction);
            this.$modal
                .show(CreateCustomAction, {
                    customActionModel,
                    isUpdate: true
                })
                .then(({ result, model }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        async handleDelete(teamCustomActionId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Event Trigger',
                    body: 'Are you sure you want to delete this event trigger?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteEventTrigger(teamCustomActionId);
                    }
                });
        },
        async deleteEventTrigger(teamCustomActionId) {
            this.$_handleLoaderState(true, 'DELETING EVENT TRIGGER...');

            await this.DELETE_EVENT_TRIGGER({ actionType: this.action, teamCustomActionId })
                .then(() => {
                    this.$notify({
                        message: 'Event trigger deleted.',
                        type: 'success'
                    });
                })
                .catch((e) => {
                    const message = 'Could not remove event trigger.';
                    showErrorMessage(this, message, e);
                })
                .finally(() => {
                    this.$_handleLoaderState(false);
                });
        },
        async openSettings() {
            this.$modal
                .show(ActionLevelSettings, {
                    action: this.action
                })
                .then(({ result, model }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        toFilterString(item) {
            if (item.filter && item.filter.status && item.filter.status.length) {
                return ` (${item.filter.status.join(', ')})`;
            }
            return '';
        },
        toSmsRecipientTypesText(recipientTypes) {
            if (this.smsRecipientOptions && recipientTypes) {
                const texts = [];
                for (let i = 0; i < recipientTypes.length; i++) {
                    const r = this.smsRecipientOptions.find((x) => x.value === recipientTypes[i]);
                    if (r) 
                        texts.push(r.text);
                }
                return texts.join(', ');
            }
            return '-';
        },
        toStopTypeString(stopTypes) {
            if (stopTypes && stopTypes.length) {
                return stopTypes.join(', ');
            }
            return '';
        },
        toTimeDelayDesc(timeDelay) {
            if (timeDelay.type === 'Immediate') {
                return 'Immediate';
            }
            if (timeDelay.type === 'Delayed') {
                return this.$options.filters.timeSpanFormat(timeDelay.delay);
            }
            if (timeDelay.type === 'Specified') {
                const time = timeDelay.specificTime.split(':');
                const m = moment();
                m.set({ hours: time[0], minutes: time[1] });
                return `At ${this.$options.filters.timeFormat(m)}`;
            }
            return '';
        },
        hasCustomTemplate(customAction) {
            return customAction.settings.smsTemplateId && customAction.settings.smsTemplateId.length;
        }
    },
    mounted() {
        this.FETCH_EVENT_TRIGGERS({ actionType: this.action });
    }
};
</script>

<style lang="scss" scoped>
.md-table-head.end {
    text-align: right;
}
::v-deep .md-table-cell.action-buttons {
    padding-right: 30px;
    text-align: right;
}
</style>
