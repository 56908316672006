var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cf-container" }, [
    _c(
      "div",
      { staticClass: "cf-action-container" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary md-just-icon md-round",
            attrs: { title: "Open Settings" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.openSettings($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("settings")])],
          1
        ),
        _c(
          "md-button",
          {
            staticClass: "md-primary md-just-icon md-round",
            attrs: { title: "Add custom action" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleAddCustomAction($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("add")])],
          1
        ),
      ],
      1
    ),
    _c("div", [_c("SmsSettings")], 1),
    _vm.filteredCustomActions && _vm.filteredCustomActions.length
      ? _c(
          "div",
          [
            _c(
              "md-table",
              { staticClass: "context-menu-support custom-paginated-table" },
              [
                _c(
                  "md-table-row",
                  [
                    _c("md-table-head", [_vm._v("Event Trigger")]),
                    _c("md-table-head", [_vm._v("Recipient(s)")]),
                    _c("md-table-head", [_vm._v("Stop Type(s)")]),
                    _c("md-table-head", [_vm._v("Time Delay")]),
                    _c("md-table-head", { staticClass: "end" }, [
                      _vm._v("Actions"),
                    ]),
                  ],
                  1
                ),
                _vm._l(_vm.filteredCustomActions, function (item) {
                  return _c(
                    "md-table-row",
                    { key: item.teamCustomActionId },
                    [
                      _c(
                        "md-table-cell",
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                item.eventTriggerName + _vm.toFilterString(item)
                              ) +
                              "\n                    "
                          ),
                          item.settings.templateName
                            ? [
                                _c("br"),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + item.settings.templateName + ")"
                                    )
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("md-table-cell", [
                        _vm._v(
                          _vm._s(
                            _vm.toSmsRecipientTypesText(
                              item.settings.smsRecipientTypes
                            )
                          )
                        ),
                      ]),
                      _c("md-table-cell", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.toStopTypeString(item.filter.stopTypes)
                            ) +
                            "\n                "
                        ),
                      ]),
                      _c("md-table-cell", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.toTimeDelayDesc(item.settings.timeDelay)
                            ) +
                            "\n                "
                        ),
                      ]),
                      _c(
                        "md-table-cell",
                        {
                          staticClass: "action-buttons",
                          attrs: { align: "end" },
                        },
                        [
                          _vm.hasCustomTemplate(item)
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-info md-just-icon md-round",
                                  attrs: {
                                    title: "Update custom template",
                                    "data-test-id":
                                      "btn-update-template-" +
                                      item.teamCustomActionId,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleUpdateTemplate(item)
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("subject")])],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "md-button",
                            {
                              staticClass: "md-warning md-just-icon md-round",
                              attrs: {
                                title: "Update custom action",
                                "data-test-id":
                                  "btn-update-" + item.teamCustomActionId,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleUpdate(
                                    item.teamCustomActionId
                                  )
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("edit")])],
                            1
                          ),
                          _c(
                            "md-button",
                            {
                              staticClass: "md-danger md-just-icon md-round",
                              attrs: {
                                title: "Delete custom action",
                                "data-test-id":
                                  "btn-delete-" + item.teamCustomActionId,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleDelete(
                                    item.teamCustomActionId
                                  )
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("delete")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ],
          1
        )
      : _c("div", [
          _c("p", { staticClass: "no-result-message" }, [
            _vm._v("No actions found."),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }