<template>
    <div>
        <form-group class="sms-settings-key" name="twilioSid" :label="accountLabel">
            <md-input v-model="smsSettings.accountId" />
        </form-group>
        <form-group class="sms-settings-key" name="twiliotoken" :label="tokenLabel">
            <md-input v-model="smsSettings.accountToken" />
        </form-group>
        <form-group class="sms-settings-key" name="sms-sent-from" label="Phone number to send sms from">
            <md-input v-model="smsSettings.outboundSmsPhoneNumber" />
        </form-group>
        <form-group class="sms-settings-key" name="sms-sent-from" label="Sender Id to send sms from">
            <md-input v-model="smsSettings.outboundSmsSenderId" />
        </form-group>
        <button class="save-sms-key-btn custom-btn" @click="update">Save</button>
        <button class="save-sms-key-btn custom-btn" @click="sendTestSMS" v-if="hasAllSettings">Send Test Sms</button>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import SendTestSmsModal from '@/components/SmsSettings/SendTestSmsModal';

export default {
    mixins: [GeneralMixin],
    data() {
        return {
            level: 'teams',
            smsSettings: {
                accountId: '',
                accountToken: '',
                outboundSmsPhoneNumber: '',
                outboundSmsSenderId: ''
            }
        };
    },

    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        accountLabel() {
            return this.user.countryCode === 'IN' ? 'Plivo Auth Id' : 'Twilio Sid';
        },
        tokenLabel() {
            return this.user.countryCode === 'IN' ? 'Plivo Auth Token' : 'Twilio Token';
        },
        hasAllSettings() {
            return (
                this.smsSettings.accountId &&
                this.smsSettings.accountId.length &&
                this.smsSettings.accountToken &&
                this.smsSettings.accountToken.length &&
                ((this.smsSettings.outboundSmsPhoneNumber && this.smsSettings.outboundSmsPhoneNumber.length) ||
                    (this.smsSettings.outboundSmsSenderId && this.smsSettings.outboundSmsSenderId.length))
            );
        }
    },
    methods: {
        async update() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const request = {
                accountId: this.smsSettings.accountId,
                accountToken: this.smsSettings.accountToken,
                outboundSmsPhoneNumber: this.smsSettings.outboundSmsPhoneNumber,
                outboundSmsSenderId: this.smsSettings.outboundSmsSenderId
            };
            const api = `/api/${this.level}/sms/settings`;
            const payload = {
                method: 'put',
                data: request
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Settings updated successfully!',
                    type: 'success'
                });
                await this.getSmsProviderCredentials();
            } catch (e) {
                const message = 'Error in updating settings';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        sendTestSMS() {
            this.$modal.show(SendTestSmsModal, { api: `/api/${this.level}/sms/test` });
        },

        async getSmsProviderCredentials() {
            this.$_handleLoaderState(true);

            const api = `/api/${this.level}/sms/settings`;
            const response = await handleRequests(api);
            if (response.data !== null) {
                this.smsSettings = response.data;
            }
            this.$_handleLoaderState(false);
        }
    },
    async mounted() {
        await this.getSmsProviderCredentials();
    }
};
</script>

<style lang="scss" scoped>
.sms-settings-key {
    margin-top: 20px;
    margin-right: 10px;
    width: 20%;
    display: inline-block;

    input {
        width: 100%;
    }
}
.save-sms-key-btn {
    display: inline-block;
    margin-right: 5px;
    width: 8% !important;
}
</style>
