<template>
    <div>
        <form-group name="settings.requestQuote" label="Request Quote">
            <md-select v-model="customActionModel.settings.requestQuote">
                <md-option v-for="item in requestQuoteOptions" :key="item" :value="item">
                    {{ item }}
                </md-option>
            </md-select>
        </form-group>
    </div>
</template>

<script>
export default {
    name: 'GetQuotesFromCarriers',
    props: {
        customActionModel: { type: Object, default: () => null }
    },
    data() {
        return {
            requestQuoteOptions: ['On Demand', 'Auto']
        };
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-container {
    top: 10px;
}

::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}
</style>
