var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-group",
        {
          staticClass: "sms-settings-key",
          attrs: { name: "twilioSid", label: _vm.accountLabel },
        },
        [
          _c("md-input", {
            model: {
              value: _vm.smsSettings.accountId,
              callback: function ($$v) {
                _vm.$set(_vm.smsSettings, "accountId", $$v)
              },
              expression: "smsSettings.accountId",
            },
          }),
        ],
        1
      ),
      _c(
        "form-group",
        {
          staticClass: "sms-settings-key",
          attrs: { name: "twiliotoken", label: _vm.tokenLabel },
        },
        [
          _c("md-input", {
            model: {
              value: _vm.smsSettings.accountToken,
              callback: function ($$v) {
                _vm.$set(_vm.smsSettings, "accountToken", $$v)
              },
              expression: "smsSettings.accountToken",
            },
          }),
        ],
        1
      ),
      _c(
        "form-group",
        {
          staticClass: "sms-settings-key",
          attrs: {
            name: "sms-sent-from",
            label: "Phone number to send sms from",
          },
        },
        [
          _c("md-input", {
            model: {
              value: _vm.smsSettings.outboundSmsPhoneNumber,
              callback: function ($$v) {
                _vm.$set(_vm.smsSettings, "outboundSmsPhoneNumber", $$v)
              },
              expression: "smsSettings.outboundSmsPhoneNumber",
            },
          }),
        ],
        1
      ),
      _c(
        "form-group",
        {
          staticClass: "sms-settings-key",
          attrs: { name: "sms-sent-from", label: "Sender Id to send sms from" },
        },
        [
          _c("md-input", {
            model: {
              value: _vm.smsSettings.outboundSmsSenderId,
              callback: function ($$v) {
                _vm.$set(_vm.smsSettings, "outboundSmsSenderId", $$v)
              },
              expression: "smsSettings.outboundSmsSenderId",
            },
          }),
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "save-sms-key-btn custom-btn",
          on: { click: _vm.update },
        },
        [_vm._v("Save")]
      ),
      _vm.hasAllSettings
        ? _c(
            "button",
            {
              staticClass: "save-sms-key-btn custom-btn",
              on: { click: _vm.sendTestSMS },
            },
            [_vm._v("Send Test Sms")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }