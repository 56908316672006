var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-group",
        { attrs: { name: "settings.requestQuote", label: "Request Quote" } },
        [
          _c(
            "md-select",
            {
              model: {
                value: _vm.customActionModel.settings.requestQuote,
                callback: function ($$v) {
                  _vm.$set(_vm.customActionModel.settings, "requestQuote", $$v)
                },
                expression: "customActionModel.settings.requestQuote",
              },
            },
            _vm._l(_vm.requestQuoteOptions, function (item) {
              return _c("md-option", { key: item, attrs: { value: item } }, [
                _vm._v("\n                " + _vm._s(item) + "\n            "),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }